import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material';

const StyledBox = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'left',
    boxShadow: 'none'
  }));
  
function Pressing({products}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatPrice = (price) => `KES ${price}`;
  const memoizedProducts = React.useMemo(() => {
    const categories = {};

    products.forEach(product => {
      const categoryName = 'dryclean';
      const formattedProduct = {
        name: product.name,
        price: {
          normal: formatPrice(product.price),
          express: formatPrice(product.expPrice),
          pressing: "KES 50" // Adjust this as needed
        }
      };

      if (!categories[categoryName]) {
        categories[categoryName] = {
          category: categoryName,
          items: []
        };
      }

      categories[categoryName].items.push(formattedProduct);
    });

    return Object.values(categories);
  }, [products])


  return (
    <section id="pricing" className="pricing">
      <div className="container" data-aos="fade-up">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledBox elevation={3}>
              {/* <div className="section-title">
                <Typography variant="h3">
                  Household <span>Items</span>
                </Typography>
              </div> */}
              {memoizedProducts.map((category, index) => (
                <div key={index}>
                 <Typography variant="h4" style={{ marginTop: '5px', textTransform:'capitalize' }}>
                    {category.category}
                  </Typography>
                  <TableContainer>
                    <Table className="box">
                      <TableHead>
                        <TableRow>
                          <TableCell className="header-cell" style={{ background: '#6D213C', color: 'white' }}>Items</TableCell>
                          <TableCell className="header-cell" style={{ background: '#6D213C', color: 'white' }}>Price</TableCell>
                          <TableCell className="header-cell" style={{ background: '#6D213C', color: 'white' }} align='right'>Express</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {category.items
                          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          ?.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.name}</TableCell>
                              <TableCell>{item.price.normal}</TableCell>
                              <TableCell align='right'>{item.price.express}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={category.items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{
                      borderTop: '1px solid #ddd',
                      paddingTop: '16px',
                      textAlign: 'right',
                      marginBottom: '16px'
                    }}
                    backIconButtonProps={{
                      style: {
                        color: '#6d213c',
                        marginRight: '8px',
                        marginBottom: '16px'
                      }
                    }}
                    nextIconButtonProps={{
                      style: {
                        color: '#6d213c',
                        marginLeft: '8px',
                        marginBottom: '16px'
                      }
                    }}
                    labelRowsPerPage="Items per page:"
                    SelectProps={{
                      style: {
                        color: '#6d213c',
                        marginBottom: '16px'
                      }
                    }}
                  />
                </div>
              ))}
            </StyledBox>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default Pressing;

import React, { useState } from "react";
import axios from 'axios';
import Grid from '@mui/material/Grid';
import HouseItems from "../components/HouseItems";
import Dryclean from "../components/DryClean";
import Pressing from "../components/Pressing";
import Laundry from "../components/Laundry";

function Catalogue() {
  const [houseHoldItems, setHouseHoldItems] = useState([]);
  const [dryCleanItems, setDryCleanItems] = useState([]);
  const [laundryItems, setLaundryItems] = useState([]);
  const [pressingItems, setPressingItems] = useState([]);

  const fetchProducts = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_HOST}/api/products/get/bbs`);
      if (res.data) {
        const houseItems = res.data.filter((t) => {
          return t.category.name !== 'laundry' && t.category.name !== 'dryclean' && t.category.name !== 'ironing'
        });
        const laundryItems = res.data.filter((t) => {
          return t.category.name === 'laundry'
        });
  
        const dryCleanItems = res.data.filter((t) => {
          return t.category.name === 'dryclean'
        });
        const pressingItems = res.data.filter((t) => {
          return t.category.name === 'ironing'
        });
        setHouseHoldItems(houseItems)
        setLaundryItems(laundryItems)
        setDryCleanItems(dryCleanItems)
        setPressingItems(pressingItems)
      }
      // console.log('houseItems', res.data.map(t => t.category.name))
     
    } catch (e) {
      return e.response;
    }
  };

  React.useEffect(() => {
    fetchProducts();
  }, []);


  return (
      <section id="pricing" className="pricing">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Pricing</h2>
            <h3>
              Check our <span>Price Catalogue</span>
            </h3>
            <p>
              Our pricing is thoughtfully designed to offer competitive rates without compromising on the exceptional quality of our services. Whether it's dry cleaning, laundry, or ironing, you can trust that our pricing reflects the care and expertise that go into every task.
            </p>
          </div>
          <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Pass filtered products to the respective components */}
            <Laundry products={laundryItems} />
          </Grid>
          <Grid item xs={12}>
            <Dryclean products={dryCleanItems} />
            </Grid>
            <Grid item xs={12}>
            <HouseItems products={houseHoldItems} />
            </Grid>
            <Grid item xs={12}>
            <Pressing products={pressingItems} />
            </Grid>
          </Grid>
        </div>
      </section>
  );
}

export default Catalogue;

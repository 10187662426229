import React from 'react';
import {Routes , Route } from 'react-router-dom';
import Catalogue from '../Pages/Catalogue';
import Services from '../Pages/Services';
import About from '../Pages/About';
import NotFoundPage from '../Pages/404';
import Home from '../Pages/Home';
import Contact from '../Pages/Contact';
import Qr from '../Pages/Qr';
import Terms from '../Pages/Terms';
import Privacy from '../Pages/Privacy';
export default function Router() {
  
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/catalogue" element={<Catalogue />} />
        <Route path="/orders/:orderId" element={<Qr />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/*" element={<NotFoundPage />} />

      </Routes>
    );
  }; 





import React from 'react';
import { Container, Typography, Box, List, ListItem, Grid } from '@mui/material';


function Terms() {
  return <>
     <Container id="about" className="about section-bg">
      <Box className="container" data-aos="fade-up">
        <Box className="section-title">
        <Typography variant="h2">Terms of Service</Typography>
          {/* <Typography variant="h3">
            Find Out More <span>About Us</span>
        </Typography>
          <p>
          Since 1997, Aisha Dry Cleaners has been the epitome of excellence in garment care and customer service. With our passion for perfection and commitment to innovation, we have become the go-to destination for all your clothing cleaning needs. Our skilled team of experts uses state-of-the-art technology and eco-friendly solutions to bring new life to your garments, ensuring they look flawless and feel fresh.
          </p> */}
        </Box>
        {/* <Grid className="row">
          <Box className="col-lg-6" data-aos="fade-right" data-aos-delay={100}>
            <img src="./images/2.jpg" className="img-fluid" alt="" />
          </Box>
          <Box
            className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <Typography variant="h3" sx={{
              p:3
            }}>
            Embrace the Luxurious Care – Where Excellence Meets Elegance!.
          </Typography>
            <p className="fst-italic">
            At Aisha Dry Cleaners, we believe that every garment tells a unique story, and we take pride in preserving those stories with utmost care and attention to detail. Our unwavering dedication to quality and customer satisfaction has earned us the trust and loyalty of countless customers over the years.
            </p>
            <List>
              <ListItem>
                <i className="bx bx-store-alt" />
                <Box>
                  <h5>Cherishing Your Garments and Our Planet – With Eco-Friendly Magic!</h5>
                  <p>
                    We believe in a sustainable tomorrow, and our commitment to the environment is as strong as our passion for garment care. That's why we proudly embrace the power of eco-friendly products in our cleaning processes.
                  </p>
                </Box>
              </ListItem>
              <ListItem>
                <i className="bx bx-images" />
                <Box>
                <h5>Beyond Perfection – Elevating the Art of Garment Care!</h5>
                  <p>
                  At Aisha Dry Cleaners, we don't just settle for perfection; we strive to surpass it with every garment we touch. Our commitment to excellence goes beyond the surface, diving deep into every crease and fold, to create a masterpiece of cleanliness and sophistication.
                  </p>
                </Box>
                
              </ListItem>
            </List>
           
          </Box>
        </Grid> */}
      </Box>
    </Container>
  </>;
}

export default Terms;
